import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Header from "../components/header";
import Layout from "../components/layout";
import { Seo } from "../components/seo";

import "../components/allblog.css";

function Blogpage({ data }) {
  if (data.prismicBloglisting.lang === "en-us") {
    var currentUrl = "/";
    var nomoreText = "No more results";
  } else if (data.prismicBloglisting.lang === "et-ee") {
    currentUrl = "/et/";
    nomoreText = "Tulemused puuduvad";
  } else {
    currentUrl = "/";
    nomoreText = "No more results";
  }

  var NextUid = data.prismicBloglisting.alternate_languages[0].uid;

  if (data.prismicBloglisting.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicBloglisting.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  var newsByCategory = {
    Cryptonews: [],
    Blog: [],
  };

  const showAll = () => {
    sessionStorage.clear();
    sessionStorage.setItem("all_cookie", document.getElementById("all_list"));

    document.getElementById("all_list").classList.add("active");
    document.getElementById("all").classList.add("activelist");

    document.getElementById("crypto_list").classList.remove("active");
    document.getElementById("cryptonews").classList.remove("activelist");

    document.getElementById("dagblog").classList.remove("activelist");
    document.getElementById("blog_list").classList.remove("active");
  };
  const showCryptonews = () => {
    sessionStorage.clear();
    sessionStorage.setItem(
      "crypto_cookie",
      document.getElementById("crypto_list")
    );

    document.getElementById("cryptonews").classList.add("activelist");
    document.getElementById("crypto_list").classList.add("active");

    document.getElementById("all_list").classList.remove("active");
    document.getElementById("all").classList.remove("activelist");

    document.getElementById("dagblog").classList.remove("activelist");
    document.getElementById("blog_list").classList.remove("active");
  };

  const showBlog = () => {
    sessionStorage.clear();
    sessionStorage.setItem("blog_cookie", document.getElementById("blog_list"));
    document.getElementById("dagblog").classList.add("activelist");
    document.getElementById("blog_list").classList.add("active");

    document.getElementById("all_list").classList.remove("active");
    document.getElementById("all").classList.remove("activelist");

    document.getElementById("crypto_list").classList.remove("active");
    document.getElementById("cryptonews").classList.remove("activelist");
  };

  var featuredPost = data.allPrismicBlog.edges;

  var allNews = data.allPrismicBlog.edges;

  for (var i = 0; i < allNews.length; i++) {
    var category = allNews[i].node.data.category;
    newsByCategory[category].push({ node: allNews[i].node });
  }

  const [list, setList] = useState([...allNews.slice(0, 9)]);

  const [loadMore, setLoadMore] = useState(false);

  const [hasMore, setHasMore] = useState(allNews.length > 3);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allNews.length;
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 3)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, list, allNews]);

  useEffect(() => {
    var crypto_cookie = sessionStorage.getItem("crypto_cookie");
    var blog_cookie = sessionStorage.getItem("blog_cookie");
    var all_cookie = sessionStorage.getItem("all_cookie");

    if (crypto_cookie !== null) {
      console.log("crypto_list active");
      document.getElementById("cryptonews").classList.add("activelist");
      document.getElementById("crypto_list").classList.add("active");

      document.getElementById("all_list").classList.remove("active");
      document.getElementById("all").classList.remove("activelist");

      document.getElementById("dagblog").classList.remove("activelist");
      document.getElementById("blog_list").classList.remove("active");
    }

    if (blog_cookie !== null) {
      console.log("blog_list active");
      document.getElementById("dagblog").classList.add("activelist");
      document.getElementById("blog_list").classList.add("active");

      document.getElementById("all_list").classList.remove("active");
      document.getElementById("all").classList.remove("activelist");

      document.getElementById("crypto_list").classList.remove("active");
      document.getElementById("cryptonews").classList.remove("activelist");
    }
    if (all_cookie !== null) {
      console.log("all_list active");
      document.getElementById("all_list").classList.add("active");
      document.getElementById("all").classList.add("activelist");

      document.getElementById("crypto_list").classList.remove("active");
      document.getElementById("cryptonews").classList.remove("activelist");

      document.getElementById("dagblog").classList.remove("activelist");
      document.getElementById("blog_list").classList.remove("active");
    }
    const isMore = list.length < allNews.length;
    setHasMore(isMore);
  }, [list, allNews]);

  var allpostsBlog = data.onlyBlog.edges;

  const [blogtag, setListblog] = useState([...allpostsBlog.slice(0, 9)]);

  const [loadMoreblog, setLoadMoreblog] = useState(false);

  const [hasMoreblog, setHasMoreblog] = useState(allpostsBlog.length > 3);

  const handleLoadMoreblog = () => {
    setLoadMoreblog(true);
  };
  useEffect(() => {
    if (loadMoreblog && hasMoreblog) {
      const currentLength = blogtag.length;
      const isMoreblog = currentLength < allpostsBlog.length;
      const nextResultsblog = isMoreblog
        ? allpostsBlog.slice(currentLength, currentLength + 3)
        : [];
      setListblog([...blogtag, ...nextResultsblog]);
      setLoadMoreblog(false);
    }
  }, [loadMoreblog, hasMoreblog]); //eslint-disable-line
  //Check if there is more
  useEffect(() => {
    const isMoreblog = blogtag.length < allpostsBlog.length;
    setHasMoreblog(isMoreblog);
  }, [blogtag]); //eslint-disable-line

  var allpostsCryptonews = data.onlyCryptonews.edges;
  // Cryptonews
  // State for the blog list only
  const [cryptonewstag, setListcryptonews] = useState([
    ...allpostsCryptonews.slice(0, 9),
  ]);
  // State to trigger oad more
  const [loadMorecryptonews, setLoadMorecryptonews] = useState(false);
  // State of whether there is more to load
  const [hasMorecryptonews, setHasMorecryptonews] = useState(
    allpostsCryptonews.length > 3
  );
  // Load more button click
  const handleLoadMorecryptonews = () => {
    setLoadMorecryptonews(true);
  };
  useEffect(() => {
    if (loadMorecryptonews && hasMorecryptonews) {
      const currentLength = cryptonewstag.length;
      const isMorecryptonews = currentLength < allpostsCryptonews.length;
      const nextResultscryptonews = isMorecryptonews
        ? allpostsCryptonews.slice(currentLength, currentLength + 3)
        : [];
      setListcryptonews([...cryptonewstag, ...nextResultscryptonews]);
      setLoadMorecryptonews(false);
    }
  }, [loadMorecryptonews, hasMorecryptonews]); //eslint-disable-line
  //Check if there is more
  useEffect(() => {
    const isMorecryptonews = cryptonewstag.length < allpostsCryptonews.length;
    setHasMorecryptonews(isMorecryptonews);
  }, [cryptonewstag]); //eslint-disable-line

  return (
    <Layout htmllang={data.prismicBloglisting.lang}>
      <Header htmllang={data.prismicBloglisting.lang} langswitcher={NextUrl} />
      <div className="container blog-page">
        <div className="page-wrapper">
          <div className="sections-container">
            <article className="page-body style-color-xsdn-bg page type-page status-publish hentry">
              <div className="post-wrapper">
                <div className="post-body">
                  {featuredPost.slice(0, 1).map((value, index) => {
                    if (
                      value.node.data.category === "Cryptonews" &&
                      data.prismicBloglisting.lang === "et-ee"
                    ) {
                      var category = "Krüptouudised";
                    } else if (
                      value.node.data.category === "Blog" &&
                      data.prismicBloglisting.lang === "et-ee"
                    ) {
                      category = "Blogi";
                    } else if (value.node.data.category === "Blog") {
                      category = "Dagblog";
                    } else {
                      category = value.node.data.category;
                    }

                    return (
                      <div className="row blog-main" key={index}>
                        <div className="col-lg-6">
                          <Link to={currentUrl + value.node.uid}>
                            <div className="post-thumb">
                              <img
                                alt={data.prismicBloglisting.data.featured.text}
                                src={value.node.data.featured_image.url}
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-6">
                          <Link to={currentUrl + value.node.uid}>
                            <h5 className="subtitle-blue">
                              {data.prismicBloglisting.data.featured.text}
                            </h5>
                            <h1>{value.node.data.title.text}</h1>
                            <p>{value.node.data.blog_content.text}</p>
                            <span className="blog-tag">
                              #{category}
                              <div className="listing-date">
                                {value.node.data.date}
                              </div>
                            </span>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                  <div className="blog-grid row latestblog">
                    <div className="col-xl-6">
                      <h5 className="subtitle-blue">
                        {data.prismicBloglisting.data.latest.text}
                      </h5>
                      {featuredPost.slice(1, 4).map((value, index) => {
                        if (
                          value.node.data.category === "Cryptonews" &&
                          data.prismicBloglisting.lang === "et-ee"
                        ) {
                          var category = "Krüptouudised";
                        } else if (
                          value.node.data.category === "Blog" &&
                          data.prismicBloglisting.lang === "et-ee"
                        ) {
                          category = "Blogi";
                        } else if (value.node.data.category === "Blog") {
                          category = "Dagblog";
                        } else {
                          category = value.node.data.category;
                        }
                        return (
                          <div className="row" key={index}>
                            <Link to={currentUrl + value.node.uid}></Link>
                            <div className="col-sm-6">
                              <div className="post-thumb">
                                <img
                                  alt={value.node.data.title.text}
                                  src={value.node.data.featured_image.url}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <h6>{value.node.data.title.text}</h6>
                              <p>{value.node.data.blog_content.text}</p>
                              <span className="blog-tag">
                                #{category}{" "}
                                <div className="listing-date">
                                  {value.node.data.date}
                                </div>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-xl-6">
                      <h5 className="subtitle-blue">
                        {data.prismicBloglisting.data.most_popular.text}
                      </h5>
                      {data.prismicBloglisting.data.featured_posts.map(
                        (value, index) => {
                          return (
                            <div className="row" key={index}>
                              <Link to={currentUrl + value.link.uid}></Link>
                              <div className="col-sm-6">
                                <div className="post-thumb">
                                  <img
                                    alt={value.titlecp.text}
                                    src={value.image.url}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <h6>{value.titlecp.text}</h6>
                                <p>{value.textcp.text}</p>
                                <span className="blog-tag">
                                  {" "}
                                  <div className="listing-date">
                                    {value.datecp.text}
                                  </div>
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="blog-grid-bottom">
                    <ul className="tag-category">
                      <li>{data.prismicBloglisting.data.categories.text}:</li>
                      <li id="blog_list" className="switcher">
                        <div
                          role="button"
                          tabIndex={i}
                          onClick={showBlog}
                          onKeyDown={showBlog}
                        >
                          {data.prismicBloglisting.data.blog.text}
                        </div>
                      </li>
                      <li id="crypto_list" className="switcher">
                        <div
                          role="button"
                          tabIndex={i}
                          onClick={showCryptonews}
                          onKeyDown={showCryptonews}
                        >
                          {data.prismicBloglisting.data.cryptonews.text}
                        </div>
                      </li>
                      <li id="all_list" className="switcher active">
                        <div
                          role="button"
                          tabIndex={i}
                          onClick={showAll}
                          onKeyDown={showAll}
                        >
                          {data.prismicBloglisting.data.all.text}
                        </div>
                      </li>
                    </ul>
                    <div className="below-content">
                      <div id="all" className="blogfilter activelist">
                        <div className="row">
                          <div className="col-12">
                            <h5 className="subtitle-blue">
                              {data.prismicBloglisting.data.all.text}
                            </h5>
                          </div>
                          <div className="selectorcryptonewsall">
                            {list.map((value, index) => {
                              if (value.node.data.category === "Blog") {
                                var category = "Dagblog";
                              } else {
                                category = value.node.data.category;
                              }
                              // {allNews.map((value, index) => {
                              return (
                                <div className="col-sm-6 col-lg-4" key={index}>
                                  <Link to={currentUrl + value.node.uid}></Link>
                                  <div className="post-thumb">
                                    <img
                                      alt={value.node.data.title.text}
                                      src={value.node.data.featured_image.url}
                                    />
                                  </div>
                                  <div className="bottom-content">
                                    <h6>{value.node.data.title.text}</h6>
                                    <div className="footerblog">
                                      <p>{value.node.data.blog_content.text}</p>
                                    </div>
                                    <span className="blog-tag long-listdata">
                                      #{category}
                                      <div className="listing-date">
                                        {value.node.data.date}
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}

                            {hasMore ? (
                              <button
                                className="loadmore"
                                onClick={handleLoadMore}
                              >
                                {data.prismicBloglisting.data.load_more.text}
                              </button>
                            ) : (
                              <p className="nomoreresults">{nomoreText}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div id="cryptonews" className="blogfilter">
                        <div className="row">
                          <div className="col-12">
                            <h5 className="subtitle-blue">
                              {data.prismicBloglisting.data.cryptonews.text}
                            </h5>
                          </div>
                          <div className="selectorcryptonews">
                            {/* {newsByCategory.Cryptonews.map((value, index) => {  */}
                            {cryptonewstag.map((value, index) => {
                              if (value.node.data.category === "Blog") {
                                var category = "Dagblog";
                              } else {
                                category = value.node.data.category;
                              }
                              return (
                                <div className="col-sm-6 col-lg-4" key={index}>
                                  <Link to={currentUrl + value.node.uid}></Link>
                                  <div className="post-thumb">
                                    <img
                                      alt={value.node.data.title.text}
                                      src={value.node.data.featured_image.url}
                                    />
                                  </div>
                                  <div className="bottom-content">
                                    <h6>{value.node.data.title.text}</h6>
                                    <div className="footerblog">
                                      <p>{value.node.data.blog_content.text}</p>
                                    </div>
                                    <span className="blog-tag long-listdata">
                                      #{category}
                                      <div className="listing-date">
                                        {value.node.data.date}
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                            {hasMorecryptonews ? (
                              <button
                                className="loadmore"
                                onClick={handleLoadMorecryptonews}
                              >
                                {data.prismicBloglisting.data.load_more.text}
                              </button>
                            ) : (
                              <p className="nomoreresults">{nomoreText}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div id="dagblog" className="blogfilter">
                        <div className="row">
                          <div className="col-12">
                            <h5 className="subtitle-blue">
                              {data.prismicBloglisting.data.blog.text}
                            </h5>
                          </div>
                          <div className="selectorblog">
                            {blogtag.map((value, index) => {
                              if (value.node.data.category === "Blog") {
                                var category = "Dagblog";
                              } else {
                                category = value.node.data.category;
                              }
                              return (
                                <div className="col-sm-6 col-lg-4" key={index}>
                                  <Link to={currentUrl + value.node.uid}></Link>
                                  <div className="post-thumb">
                                    <img
                                      alt={value.node.data.title.text}
                                      src={value.node.data.featured_image.url}
                                    />
                                  </div>
                                  <div className="bottom-content">
                                    <h6>{value.node.data.title.text}</h6>
                                    <div className="footerblog">
                                      <p>{value.node.data.blog_content.text}</p>
                                    </div>
                                    <span className="blog-tag long-listdata">
                                      #{category}
                                      <div className="listing-date">
                                        {value.node.data.date}
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}

                            {hasMoreblog ? (
                              <button
                                className="loadmore"
                                onClick={handleLoadMoreblog}
                              >
                                {data.prismicBloglisting.data.load_more.text}
                              </button>
                            ) : (
                              <p className="nomoreresults">{nomoreText}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Add description
        pathname={data.prismicBloglisting.uid}
        // TODO: Title should come from "seo_title" query
        title={data.prismicBloglisting.data.page_title.text}
        // lang={data.prismicBloglisting.lang}
      />
    </>
  );
};

export const query = graphql`
  query AllBlogPostQuery($uid: String, $lang: String) {
    prismicBloglisting(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        featured_posts {
          titlecp {
            text
          }
          textcp {
            text
          }
          link {
            uid
          }
          image {
            url
            dimensions {
              height
              width
            }
          }
          datecp {
            text
          }
        }
        all {
          text
        }
        blog {
          text
        }
        cryptonews {
          text
        }
        categories {
          text
        }
        dagnews {
          text
        }
        featured {
          text
        }
        latest {
          text
        }
        load_more {
          text
        }
        most_popular {
          text
        }
        page_title {
          text
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          data {
            blog_content {
              text
            }
            category
            date(formatString: "DD MMMM, YYYY", locale: "en")
            featured_image {
              url
            }
            title {
              text
            }
          }
          uid
          lang
        }
      }
    }
    onlyBlog: allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      filter: { data: { category: { eq: "Blog" } }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          data {
            blog_content {
              text
            }
            category
            date(formatString: "DD MMMM, YYYY", locale: "en")
            featured_image {
              url
            }
            title {
              text
            }
          }
          uid
          lang
        }
      }
    }
    onlyCryptonews: allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      filter: { data: { category: { eq: "Cryptonews" } }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          data {
            blog_content {
              text
            }
            category
            date(formatString: "DD MMMM, YYYY", locale: "en")
            featured_image {
              url
            }
            title {
              text
            }
          }
          uid
          lang
        }
      }
    }
  }
`;

export default Blogpage;
